import React from 'react';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

const solutions = [
  {
    title: 'AI B2B Solutions',
    description: 'Transform your business operations with cutting-edge AI solutions',
    image: 'https://images.unsplash.com/photo-1677442136019-21780ecad995?auto=format&fit=crop&q=80',
    link: '/products/ai-b2b'
  },
  {
    title: 'IoT Solutions',
    description: 'Connect and optimize your devices with our comprehensive IoT platform',
    image: 'https://images.unsplash.com/photo-1558346490-a72e53ae2d4f?auto=format&fit=crop&q=80',
    link: '/products/iot-solutions'
  },
  {
    title: 'Fullstack Solutions',
    description: 'End-to-end web development solutions for modern businesses',
    image: 'https://images.unsplash.com/photo-1517694712202-14dd9538aa97?auto=format&fit=crop&q=80',
    link: '/products/fullstack'
  },
  {
    title: 'ICT Services',
    description: 'Comprehensive technology infrastructure and support services',
    image: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80',
    link: '/products/ict-services'
  }
];

const SolutionsHeroSlider = () => {
  return (
    <section className="relative h-screen bg-gray-900">
      <Swiper
        modules={[Navigation, Pagination, Autoplay, EffectFade]}
        effect="fade"
        spaceBetween={0}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000 }}
        className="h-full"
      >
        {solutions.map((solution, index) => (
          <SwiperSlide key={index}>
            <div className="relative h-full">
              <div className="absolute inset-0">
                <img
                  src={solution.image}
                  alt={solution.title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-black/70 to-black/30" />
              </div>
              <div className="relative h-full flex items-center">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    className="max-w-3xl"
                  >
                    <h2 className="text-4xl md:text-6xl font-bold text-white mb-6">
                      {solution.title}
                    </h2>
                    <p className="text-xl text-gray-200 mb-8">
                      {solution.description}
                    </p>
                    <Link
                      to={solution.link}
                      className="inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#9b87f5] hover:bg-[#8b77e5] transition-colors"
                    >
                      Learn More
                      <ArrowRight className="ml-2" size={20} />
                    </Link>
                  </motion.div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default SolutionsHeroSlider;