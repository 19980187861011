import React from 'react';
import ProductLayout from '../ProductLayout';
import ICTIntro from './ICTIntro';
import ICTServices from './ICTServices';

const ICTServicesSolutions = () => {
  return (
    <ProductLayout
      title="ICT Services Solutions"
      description="Comprehensive ICT services for modern enterprises"
      backgroundImage="https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80"
    >
      <ICTIntro />
      <ICTServices />
    </ProductLayout>
  );
};

export default ICTServicesSolutions;