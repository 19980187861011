import React from 'react';
import { motion } from 'framer-motion';

const ICTIntro = () => {
  return (
    <section className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="prose prose-lg max-w-none"
        >
          <p className="text-gray-700 leading-relaxed">
            Our ICT Services Solutions provide comprehensive technology infrastructure and support services designed to help organizations optimize their operations, enhance security, and drive digital transformation. From network infrastructure and cloud services to cybersecurity and managed IT support, we deliver end-to-end solutions that enable businesses to leverage technology effectively while minimizing risks and maximizing returns on investment.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default ICTIntro;