import React from 'react';
import { motion } from 'framer-motion';
import { Network, Cloud, Shield, Server, Users, Headphones, Cable, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const services = [
  {
    icon: Network,
    title: 'Network Infrastructure',
    description: 'Design, implementation, and maintenance of robust network solutions including LAN, WAN, and wireless networks.'
  },
  {
    icon: Cloud,
    title: 'Cloud Services',
    description: 'Comprehensive cloud solutions including migration, hosting, and management of cloud infrastructure.'
  },
  {
    icon: Shield,
    title: 'Cybersecurity',
    description: 'Advanced security solutions to protect your digital assets, including threat detection, prevention, and response.'
  },
  {
    icon: Cable,
    title: 'Structured Cabling Systems',
    description: 'Professional installation and maintenance of organized cabling infrastructure for data and telecommunications.'
  },
  {
    icon: Server,
    title: 'Data Center Solutions',
    description: 'Modern data center design and management services for optimal performance and reliability.'
  },
  {
    icon: Users,
    title: 'IT Consulting',
    description: 'Strategic technology consulting to align IT initiatives with business objectives.'
  },
  {
    icon: Headphones,
    title: 'Managed IT Support',
    description: '24/7 technical support and maintenance services to ensure smooth operations.'
  }
];

const ICTServices = () => {
  return (
    <section className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Our ICT Services</h2>
          <p className="text-gray-600 max-w-2xl mx-auto mb-8">
            Comprehensive technology solutions to power your digital transformation
          </p>
          <Link
            to="/contact"
            className="inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#9b87f5] hover:bg-[#8b77e5] transition-colors"
          >
            Contact Sales
            <ArrowRight className="ml-2" size={20} />
          </Link>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={service.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white p-6 rounded-lg shadow-lg"
            >
              <div className="flex flex-col items-center text-center">
                <service.icon className="w-12 h-12 text-[#9b87f5] mb-4" />
                <h3 className="text-xl font-bold text-gray-900 mb-2">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ICTServices;