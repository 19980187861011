import React from 'react';
import { motion } from 'framer-motion';

const IoTIntro = () => {
  return (
    <section className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="prose prose-lg max-w-none"
        >
          <p className="text-gray-700 leading-relaxed">
            The Internet of Things (IoT) represents a transformative technological ecosystem where every day physical devices are embedded with sensors, software, and network connectivity, enabling them to collect and exchange data. From smart home thermostats and wearable fitness trackers to industrial sensors and connected vehicles, IoT technology allows devices to communicate and interact with each other and centralized systems, creating unprecedented opportunities for automation, monitoring, and intelligent decision-making. By bridging the physical and digital worlds, IoT is revolutionizing industries like healthcare, manufacturing, agriculture, and urban infrastructure, offering enhanced efficiency, real-time insights, and the potential to solve complex challenges through interconnected, data-driven solutions.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default IoTIntro;